<template>
    <div class="source-box">
        <div class="pic-box">
            <div class="chacked" :class="list.check ? 'active' : ''">
                <i class="el-icon-check"></i>
            </div>
            <div class="video-edit-box">
                <!-- <el-checkbox v-model="item.checked" /> -->
                <el-dropdown
                    placement="bottom"
                    @command="(command) => onCommand(command, list)"
                >
                    <span class="el-dropdown-link">
                        <i class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">删除</el-dropdown-item>
                        <el-dropdown-item command="2">移动</el-dropdown-item>
                        <el-dropdown-item command="3">重命名</el-dropdown-item>
                        <!-- <el-dropdown-item command="4"
                            >更换封面</el-dropdown-item
                        > -->
                        <!-- <el-dropdown-item command="download"
                            >下载</el-dropdown-item
                        > -->
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <span class="icon-btn" @click.stop="preview">
                <i class="iconfont icon-bofangjian"></i>
            </span>
            <!-- 图片格式 -->
            <el-image
                :src="list.coverUrl"
                class="img_box"
                v-if="list.contentType == 'image/png'"
                fit="contain"
            >
                <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                </div>
            </el-image>
            <!-- 音频 -->
            <el-image
                class="img_box"
                :src="list.coverUrl"
                fit="contain"
                :lazy="true"
                v-else-if="list.contentType == 'audio/mpeg'"
            >
                <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                    <div>获取链接失效</div>
                </div>
                <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                </div>
            </el-image>
            <!-- 视频封面 -->
            <el-image
                class="img_box"
                width
                :src="list.coverUrl"
                :fit="list.width > list.height ? 'cover' : 'contain'"
                :lazy="true"
                v-else
            >
                <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                    <div>获取链接失效</div>
                </div>
                <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                </div>
            </el-image>
        </div>
        <div class="video_info">
            <!-- <div class="ellipsis">名称：{{ list.fileName }}</div> -->
            <div class="ellipsis">名称：{{ list.fileName }}</div>
            <div class="ellipsis">尺寸：{{ list.width }}*{{ list.height }}</div>
            <div class="flex justify">
                <div>时长：{{ list.duration }} s</div>
                <div class="createUser ellipsis">
                    创建者：{{ list.createUser }}
                </div>
            </div>
        </div>
        <!-- <el-dialog
            title="选择目录"
            :visible.sync="showMenu"
            width="430px"
            :before-close="handleClose"
        >
            <div class="menu_box">
                <el-cascader
                    style="width: 100%"
                    v-model="menuPath"
                    :options="menus"
                    :props="menuProps"
                    clearable
                    @change="changeMenu"
                    ref="select"
                ></el-cascader>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showMenu = false" size="small"
                    >取 消</el-button
                >
                <el-button type="primary" size="small" @click="gitFission"
                    >确 定</el-button
                >
            </span>
        </el-dialog> -->
    </div>
</template>

<script>
import {
    getSource,
    delSourceAll,
    delSource,
    download,
    resetMeunName,
    resetName,
} from "@/api/api.js";
export default {
    name: "videItem",
    props: ["list"],
    data() {
        return {
            audioBG: require("@/assets/images/audio.jpeg"),
            showMenu: true,
            menus: [], //目录
            menuPath: [],
            menuProps: {
                checkStrictly: true,
                value: "id",
            },
        };
    },

    mounted() {},

    methods: {
        preview() {
            this.$emit("getPreview", this.list);
        },
        // 素材下拉操作
        onCommand(command, data) {
            switch (command) {
                case "1":
                    this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            let json = { ids: this.list.id };
                            delSourceAll(json).then((res) => {
                                if (res.code == 200) {
                                    // this.getSource();
                                    this.$emit("getDel");
                                    this.$message.success("删除成功");
                                } else {
                                    this.$message.error("删除失败");
                                }
                            });
                        })
                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "已取消删除",
                            });
                        });
                    break;
                case "2":
                    this.$emit("moveMeun", data);
                    break;
                case "3":
                    this.open_set_name(data);

                    break;
                case "4":
                    console.log("更换封面");
                    break;
                default:
                    break;
            }
        },
        open_set_name(data) {
            // console.log(data);
            this.$prompt(`修改文件名：${data.fileName}`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPlaceholder: "请输入新的文件名",
                // inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/,
                // inputErrorMessage: "文件命不能包含特殊字符跟空格",
            }).then(({ value }) => {
                const data = {
                    materialId: this.list.id,
                    name: value,
                };
                resetName(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success("修改成功");
                        this.list.fileName = value;
                    }
                });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.source-box {
    position: relative;
    width: 188px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    &:hover {
        .icon-btn {
            background-color: rgba(0, 0, 0, 0.6);
            cursor: pointer;
        }
        .video-edit-box,
        .chacked {
            display: block;
        }
    }
    .video_info {
        // position: absolute;
        width: 100%;
        // bottom: 0;
        // background-color: rgba(0, 0, 0, 0.6);
        // color: #fff;
        // text-align: center;
        font-size: 12px;
        padding: 4px 10px;
        box-sizing: border-box;
        color: #8590b3;
        .flex {
            display: flex;
        }
        .justify {
            justify-content: space-between;
        }
    }
}
.createUser {
    flex: 0 0 50%;
    width: 0;
}
.pic-box {
    position: relative;
    line-height: 0;
    &.active {
        border-color: #349ffd;
        background-color: #349ffd;
    }
    .chacked {
        &.active {
            background-color: #349ffd;
            display: block !important;
        }
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        font-size: 12px;
        border-radius: 8px;
        position: absolute;
        left: 8px;
        top: 8px;
        z-index: 999;
        // background-color: #349ffd;
        background-color: #c8cfd4;
        color: #fff;
        display: none;
    }
    .video-edit-box {
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 999;
        display: none;
        .el-icon-more {
            color: #8597b2;
            background-color: #f5f6f8;
            padding: 2px 4px;
            font-size: 12px;
        }
    }
}
.icon-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.4);
    // padding: 10px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    .iconfont {
        color: rgba(255, 255, 255, 0.8);
    }
}
.img_box {
    width: 100%;
    height: 188px;
    background: url(../../assets/images/ma.png) repeat;
    background-size: 8px;
    position: relative;
}
.image-slot {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #666;
    i {
        font-size: 36px;
        color: #656565;
    }
}
</style>