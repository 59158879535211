import request from '@/utils/request'
export function upflie(data) {
    console.log(data);
    return request({
        url: '/app/Videocreationfile/multiple',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data
    })
}
// 获取oss签名
export function getSignOss(data) {
    return request({
        url: '/api/app/Videocreationfile/signSts',
        method: 'post',
        data
    })
}