<style>
.el-progress__text {
    font-size: 12px !important;
}
</style>
<template>
    <div class="ku-box">
        <!-- 分组管理导航 -->
        <div class="left">
            <div class="material">
                <div class="material-title" @click="back">
                    <p><i class="el-icon-arrow-left"></i></p>
                    <span v-if="material == 'Material'">素材原料</span>
                    <span v-if="material == 'mev'">创意库</span>
                </div>
                <!-- <el-input placeholder="搜索名称"></el-input> -->
                <div class="meun-box">
                    <MENU
                        :material="where.projectType"
                        :impoType="impoType"
                        @senData="getChilderData"
                    />
                </div>
            </div>
        </div>
        <!-- 视频列表 -->
        <div class="right">
            <div class="right-box">
                <div class="nav">
                    <div class="nav-top">
                        <div class="type_select">
                            <ul class="select-row">
                                <li
                                    class="select-item"
                                    :class="
                                        where.contentType == '' ? 'active' : ''
                                    "
                                    ref="type_list"
                                    @click="tab('')"
                                    v-if="!sourec_type"
                                >
                                    全部
                                </li>
                                <li
                                    class="select-item"
                                    :class="
                                        where.contentType == 'video'
                                            ? 'active'
                                            : ''
                                    "
                                    ref="type_list"
                                    @click="tab('video')"
                                    v-if="sourec_type == 1 || !sourec_type"
                                >
                                    视频
                                </li>
                                <li
                                    class="select-item"
                                    :class="
                                        where.contentType == 'audio'
                                            ? 'active'
                                            : ''
                                    "
                                    ref="type_list"
                                    @click="tab('audio')"
                                    v-if="sourec_type == 2 || !sourec_type"
                                >
                                    音频
                                </li>
                                <li
                                    class="select-item"
                                    :class="
                                        where.contentType == 'image'
                                            ? 'active'
                                            : ''
                                    "
                                    ref="type_list"
                                    @click="tab('image')"
                                    v-if="sourec_type == 3 || !sourec_type"
                                >
                                    图片
                                </li>
                            </ul>
                        </div>
                        <!-- <div v-if="type == 3">点击下方选择需要导入的素材</div> -->
                        <div class="">
                            <!-- <div
                            class=""
                            v-if="material == 'Material' || material == 'mev'"
                        > -->
                            <!-- action="/api/app/Videocreationfile/multiple" -->
                            <el-upload
                                ref="upload"
                                style="display: inline-block"
                                action="/api/app/Videocreationfile/multiple"
                                :data="{
                                    projectType: 'Material',
                                    directoryId: selectMeun.id,
                                }"
                                :headers="{ Authorization: token }"
                                name="files"
                                multiple
                                accept="video/*,image/*,audio/*"
                                :on-success="onSuccess"
                                :on-error="onError"
                                :show-file-list="false"
                                :before-upload="beforeAvatarUpload"
                                :http-request="fnUploadRequest"
                            >
                                <el-button size="mini" type="primary"
                                    >上传素材</el-button
                                >
                            </el-upload>
                        </div>
                    </div>
                </div>
                <div class="sucai-box" ref="sucai_content">
                    <div class="sucai-group">
                        <div
                            class="sucai-list"
                            style="justify-content: center"
                            :style="{ width: item_width + 'px' }"
                            v-for="(item, index) in uplist"
                            :key="index"
                        >
                            <el-progress
                                type="circle"
                                :format="format"
                                :percentage="item.p"
                                :stroke-width="4"
                                :width="100"
                            >
                            </el-progress>
                        </div>
                        <!-- 素材列表 -->
                        <div
                            class="sucai-list"
                            :style="{ width: item_width + 'px' }"
                            v-for="(item, index) in list"
                            :key="item.uid"
                            :ref="'sucai_list' + index"
                        >
                            <div
                                class="source_list"
                                @click="changeItem(item)"
                                :class="item.check ? 'active' : ''"
                            >
                                <VIEDO_ITEM
                                    :list="item"
                                    @getPreview="openPreview"
                                    @getDel="delSource"
                                    @moveMeun="moveMeun"
                                />
                            </div>
                        </div>
                        <div
                            class="sucai-gruop"
                            :class="list.length == 0 ? 'notup' : 'add'"
                        >
                            <div v-if="list.length == 0 && uplist.length == 0">
                                <el-empty
                                    description="找不到任何素材哦"
                                ></el-empty>
                            </div>
                        </div>
                    </div>
                    <div class="page-box">
                        <div class="page-item">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page.sync="where.currentPage"
                                :page-size="where.pageSize"
                                :page-sizes="pageSizes"
                                layout="sizes,prev, pager, next,total"
                                :total="total"
                                hide-on-single-page
                            >
                            </el-pagination>
                        </div>
                        <div class="edit-item">
                            <div class="select-num">
                                已选：<span class="num">{{
                                    select_data.length
                                }}</span>
                            </div>
                            <el-button
                                type="primary"
                                size="mini"
                                @click="allSelect"
                                >全选</el-button
                            >
                            <el-button
                                type="primary"
                                size="mini"
                                @click="download"
                                >下载</el-button
                            >
                            <el-button
                                size="mini"
                                v-if="select_data.length"
                                @click="escAll"
                                >取消选择</el-button
                            >
                            <el-button
                                size="mini"
                                v-if="select_data.length"
                                @click="deleteAll"
                                >删除</el-button
                            >
                        </div>
                    </div>
                </div>
                <div class="select-box" v-if="select_data.length">
                    <div class="select-group">
                        <div
                            class="select-item"
                            v-for="(item, index) in select_data"
                            :key="index"
                        >
                            <div
                                class="del_btn"
                                @click="del_select(item, index)"
                            >
                                <i class="el-icon-delete-solid"></i>
                            </div>
                            <el-image
                                style="width: 100%; height: 100%"
                                :src="item.coverUrl"
                                fit="contain"
                            ></el-image>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 预览 -->
        <el-dialog
            title="预览"
            :visible.sync="showPreview"
            :width="previewData.width > previewData.height ? '560px' : '280px'"
            :before-close="hidePreview"
        >
            <div>
                <div v-show="openType == 1">
                    <img
                        width="100%"
                        ref="pic"
                        :src="previewData.playUrl"
                        alt=""
                    />
                </div>
                <div v-show="openType == 2">
                    <video
                        ref="video"
                        width="100%"
                        height=""
                        :src="previewData.playUrl"
                        controls
                    ></video>
                </div>
                <div v-show="openType == 3">
                    <audio
                        ref="audio"
                        width="100%"
                        :src="previewData.playUrl"
                        controls
                    ></audio>
                </div>
            </div>
        </el-dialog>

        <el-dialog
            :title="addGroupDialog.title"
            :visible.sync="addGroupDialog.show"
            width="30%"
        >
            <addGrouping
                :prop="addGroupDialog"
                @addGroup="addGroup"
                v-if="addGroupDialog.type === 'addGroup'"
            />
            <addVideo v-if="addGroupDialog.type === 'addVideo'" />
            <addMove :nav="nav" v-if="addGroupDialog.type === 'addMove'" />
        </el-dialog>
        <el-dialog title="选择目录" :visible.sync="showMenu" width="430px">
            <div class="menu_box">
                <div class="">文件：{{ move_data.fileName }} 移动到</div>
                <el-cascader
                    v-model="menuPath"
                    :options="menus"
                    :props="menuProps"
                    clearable
                    @change="changeMenu"
                    ref="select"
                ></el-cascader>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showMenu = false" size="small"
                    >取 消</el-button
                >
                <el-button type="primary" size="small" @click="gitMeun"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Bus from "@/utils/eventBus";
import MENU from "@/components/work/ku_menu.vue";
import VIEDO_ITEM from "@/components/work/video_item.vue";
import {
    getSource,
    delSourceAll,
    delSource,
    download,
    moveMaterial,
    getMuenTree,
} from "@/api/api.js";
import { getVideoImg, checkExists } from "@/utils/utils";

import OSS from "ali-oss";
import { getSignOss } from "@/api/upload";

import JSZip from "jszip";
import FileSaver from "file-saver";

export default {
    name: "SourceMaterial",
    components: {
        MENU,
        VIEDO_ITEM,
    },
    props: {
        material: {
            // 设置默认值
            default: "material",
            // 指定传值类型
            type: String,
            // 是否必须传值,父组件不传值会报警告
            required: false,
        },
        impoType: {
            default: 1,
            type: Number,
        },
        sourec_type: {
            default: 0, // 1.视频 2.音频 3.图片
            type: Number,
        },
    },
    data() {
        return {
            upLink: "/api/app/Videocreationfile/multiple",
            upload: true,
            type: this.material == "Material" ? 2 : 1,
            //树目录选择的节点信息
            selectMeun: {
                id: "",
                label: "我的项目",
            },
            //素材选择列表
            checkeds: [],
            //   是否全选
            checkboxState: false,
            //上传列表
            uplist: [],
            list: [], //对应树节点的素材列表
            total: 0, //总记录数
            pagination: {},

            token: this.$store.state.token,
            //   dialogVisible: false,
            //   新增获取修改目录信息
            addGroupDialog: {
                type: "",
                show: false,
                title: "",
            },
            show: true,
            nav: [],
            loading: "",
            where: {
                directoryId: "", //目录ID
                projectType: this.material, //素材类型 //Material素材库   //mev创意库
                currentPage: 1, //当前页码
                pageSize: 10, //每页条数
                contentType: "",
            },
            pageSizes: [10, 20, 50, 80, 100],
            disabled: false,
            openType: "3", //1.图片，2.视频 3。音频
            showPreview: false, //打开预览
            // preview: "", //预览链接
            previewData: {},
            item_width: 188,
            select_data: [],
            upType: "video", //上传类型

            // 素材移动
            showMenu: false, //是否显示选择目录弹框
            menus: [], //目录
            menuPath: [],
            menuProps: {
                checkStrictly: true,
                value: "id",
            },
            move_data: {},
        };
    },

    mounted() {
        // console.log(this.sourec_type);
        switch (this.sourec_type) {
            case 1:
                this.where.contentType = "video";
                break;

            case 2:
                this.where.contentType = "audio";
                break;

            case 3:
                this.where.contentType = "image";
                break;

            default:
                break;
        }
        const SUCAI_CONTENT = this.$refs.sucai_content;
        let sucai_box_width = SUCAI_CONTENT.clientWidth - 18;
        let row_num = Math.floor(sucai_box_width / 188);
        this.where.pageSize = row_num * 2;
        let item_width = Math.floor((sucai_box_width / row_num) * 100) / 100;
        this.item_width = item_width;
        this.pageSizes.unshift(this.where.pageSize);
        this.getSource();
    },
    created() {
        this.$nextTick(() => {});
        this.getMuen();
    },
    methods: {
        changeMenu(val) {
            this.$refs.select.dropDownVisible = false;
        },
        async getMuen() {
            let muen = await getMuenTree({
                materialLibraryType: this.type,
            });
            this.menus = muen.data;
        },
        async gitMeun() {
            // console.log(this.menuPath);
            let toId = this.menuPath[this.menuPath.length - 1];

            let list = this.list;
            // console.log(toId);
            //移动数据
            let move_data = this.move_data;
            let data = {
                materialId: move_data.id,
                targetMaterialLibraryId: toId,
            };
            let res = await moveMaterial(data);
            // console.log(res);
            if (res.code == 200) {
                this.$message.success("移动成功");
                let index = list.findIndex((res) => res.id === move_data.id);
                list.splice(index, 1);
                this.showMenu = false;
            }
        },
        moveMeun(item) {
            this.showMenu = true;
            this.move_data = item;
        },
        // 点击下载
        download() {
            const loading = this.$loading({
                lock: true,
                text: "正在下载资源",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            const datas = this.select_data;
            var blogTitle = `火键素材`; // 下载后压缩包的命名
            var zip = new JSZip();
            var promises = [];
            let cache = {};
            let arrImg = [];
            for (let i = 0; i < datas.length; i++) {
                let flieType = datas[i].fileUrl.split(".");
                let flieCate = datas[i].contentType.split("/");
                let url = "";
                switch (flieCate[0]) {
                    case "audio":
                        url = this.$store.state.ossAudioUrl;
                        break;

                    case "video":
                        url = this.$store.state.ossVideoUrl;
                        break;

                    case "image":
                        url = this.$store.state.ossImgUrl;
                        break;

                    default:
                        this.$message.error("解析文件类型失败！");

                        loading.close();
                        return;
                }
                arrImg.push({
                    path: url + datas[i].fileUrl, // 文件链接
                    name: `${datas[i].fileName}.${flieType[1]}`, // 文件名称
                });
            }

            for (let item of arrImg) {
                // item.path为文件链接地址
                const promise = this.getImgArrayBuffer(item.path).then(
                    (data) => {
                        // 下载文件, 并存成ArrayBuffer对象(blob)
                        zip.file(item.name, data, { binary: true }); // 逐个添加文件
                        cache[item.name] = data;
                    }
                );
                promises.push(promise);
            }

            Promise.all(promises)
                .then(() => {
                    zip.generateAsync({ type: "blob" }).then((content) => {
                        // 生成二进制流
                        console.log(content);
                        FileSaver.saveAs(content, blogTitle + ".zip"); // 利用file-saver保存文件  自定义文件名
                        loading.close();
                    });
                })
                .catch((res) => {
                    alert("文件压缩失败");
                    loading.close();
                });
        },
        //文件以流的形式获取（参数url为文件链接地址）
        getImgArrayBuffer(url) {
            return new Promise((resolve, reject) => {
                //通过请求获取文件blob格式
                let xmlhttp = new XMLHttpRequest();
                xmlhttp.open("GET", url, true);
                xmlhttp.responseType = "blob";
                xmlhttp.onload = function () {
                    if (this.status == 200) {
                        resolve(this.response);
                    } else {
                        reject(this.status);
                    }
                };
                xmlhttp.send();
            });
        },
        tab(type) {
            if (type == this.where.contentType) return;
            this.where.contentType = type;
            this.where.currentPage = 1;
            getSource(this.where).then((res) => {
                this.list = res.data.list;
                this.total = res.data.pagination.total;
            });
        },
        changeItem(item) {
            if (item.check) {
                // 取消选择
                this.$set(item, "check", false);
                console.log(item.id);
                let index = this.select_data.findIndex(
                    (info) => info.id == item.id
                );
                this.select_data.splice(index, 1);
            } else {
                // 选中
                this.$set(item, "check", true);
                this.select_data.unshift(item);
            }
        },
        del_select(item, index) {
            let info = this.list.find((info) => item.id === info.id);

            this.select_data.splice(index, 1);

            // console.log(info);
            if (!info) return;
            this.$set(info, "check", false);
        },
        openPreview(item) {
            let type = item.contentType.match(/(\S*)\//)[1];
            let ossUrl = "";
            if (type == "video") {
                console.log();
                ossUrl = this.$store.state.ossVideoUrl;
                this.openType = 2;
            } else if (type == "audio") {
                ossUrl = this.$store.state.ossAudioUrl;
                this.openType = 3;
            } else {
                ossUrl = this.$store.state.ossImgUrl;
                this.openType = 1;
            }
            // console.log(ossUrl);
            item.playUrl = ossUrl + item.fileUrl;
            // console.log(item.fileUrl);
            this.previewData = item;
            // console.log(item.Url);
            this.showPreview = true;

            // console.log(item);
            // if (this.impoType == 1) {
            //     this.previewData = item;
            //     this.showPreview = true;
            // }
        },

        hidePreview() {
            this.previewData = "";
            this.showPreview = false;
            this.$refs.audio.src = "";
            this.$refs.video.src = "";
        },
        format(percentage) {
            return percentage == 100
                ? "正在保存"
                : `${percentage.toFixed(2)} %`;
        },
        async getChilderData(data) {
            // 获取点击目录下的文件
            this.selectMeun = data;
            this.list = [];
            this.where.currentPage = 1;
            this.where.directoryId = data.id;

            let res = await getSource(this.where);

            this.list = res.data.list;

            this.total = res.data.pagination.total;
        },
        back() {
            if (this.type == 3) {
                // 导入 隐藏弹框
                console.log("隐藏导入弹框");
            } else {
                this.$goBack();
            }
        },
        // 上传限制格式
        beforeAvatarUpload(file) {
            let type = file.type.split("/");
            this.type = type[0];
            console.log(file);
            // return false;
            return new Promise((resolve, reject) => {
                // console.log(file);
                let isPNG = file.type === "image/png";
                let isMP4 = file.type === "video/mp4";
                let isMP3 = file.type === "audio/mpeg";
                let isMov = file.type === "video/quicktime";
                let isLt100M = file.size / 1024 / 1024;
                if (isLt100M > 100) {
                    console.log(isLt100M);
                    this.$message.error("文件大于100M");
                    reject(false);
                }

                let isMaterial =
                    type[0] == "video" ||
                    type[0] == "audio" ||
                    type[0] == "image";

                if (!isMaterial) {
                    this.$message.error("只能上传video,audio,image格式!");
                    reject(false);
                } else {
                    resolve(true);
                }
            });
        },
        onError(response, file, fileList) {
            console.log(response);
            console.log("error");
        },
        // 上传成功后
        async onSuccess(response, file, fileList) {
            let index = this.uplist.findIndex((res) => res.uid === file.uid);
            this.uplist.splice(index, 1);
            this.getSource();
        },
        // 自定义上传
        async fnUploadRequest(options) {
            let sts = await getSignOss({ fileType: this.type });
            sts = sts.data;
            console.log(sts);
            const client = new OSS({
                bucket: sts.bucket,
                accessKeyId: sts.AccessKeyId,
                accessKeySecret: sts.AccessKeySecret,
                stsToken: sts.SecurityToken,
                region: "oss-cn-shanghai",
                refreshSTSToken: async () => {
                    const info = await getSignOss({ fileType: this.type });
                    return {
                        bucket: info.data.bucket,
                        accessKeyId: info.data.AccessKeyId,
                        accessKeySecret: info.data.AccessKeySecretd,
                        stsToken: info.data.SecurityToken,
                    };
                },
                timeout: 300000,
            });
            let file = options.file;
            // console.log(file);
            let fileType = file.type;

            let fileName = file.name;
            let suffix = fileName.split(".")[1];
            let name = Date.now();

            const fileUrl = `${sts.dir}${name}.${suffix}`;
            let upList = {
                uid: file.uid,
                file: file,
                path: fileUrl,
                p: 0,
            };
            this.uplist.push(upList);

            if (!fileUrl) {
                return;
            }
            try {
                const res = await client.multipartUpload(fileUrl, file, {
                    //防止覆盖
                    headers: { "x-oss-forbid-overwrite": true },
                    // 获取分片上传进度、断点和返回值。
                    progress: (p, cpt, res) => {
                        let info = this.uplist.find(
                            (info) => info.uid == cpt.file.uid
                        );
                        info.p = p * 100;
                    },
                    // 设置并发上传的分片数量。
                    parallel: 4,
                    // 设置分片大小。默认值为1 MB，最小值为100 KB。
                    partSize: 1024 * 1024 * 10,
                    mime: fileType,
                    callback: {
                        url: "https://hotkeey.cn/api/app/callBack/doPost",
                        // host: "https://hotkeey.cn",
                        // contentType: "application/x-www-form-urlencoded",
                        contentType: "application/json",
                        body: `bucket=${sts.bucket}&fileUrl=${fileUrl}&fileName=${fileName}&token=${this.token}&directoryId=${this.selectMeun.id}&projectType=${this.material}&contentType=${fileType}`,
                        // body: `'bucket'=${sts.bucket}&'fileUrl'=${fileUrl}&'fileName'=${fileName}&'token'=${this.token}&'directoryId'=${this.selectMeun.id}&'projectType'=${this.material}&'contentType'=${fileType}`,
                        // body: `'bucket'=${sts.bucket}`,
                    },
                });
            } catch (error) {
                console.log(error);
            }
        },
        //选择全部
        allSelect() {
            this.list.forEach((item) => {
                if (!item.check) {
                    this.$set(item, "check", true);
                    this.select_data.unshift(item);
                }
            });
        },
        // 全部删除
        deleteAll() {
            let id = [];
            this.select_data.map((item) => {
                id.push(item.id);
            });
            if (id.length) {
                this.$confirm(
                    `此操作将永久删除已选的${id.length}个文件, 是否继续?`,
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: "Loading",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    let ids = id.toString();
                    let json = { ids };
                    // console.log(id);
                    delSourceAll(json).then((res) => {
                        if (res.code == 200) {
                            this.getSource();
                            this.select_data = [];
                            // this.checkeds = [];
                            this.$message.success("删除成功");
                        } else {
                            this.$message.error("删除失败");
                        }
                    });
                    loading.close();
                });
            } else {
                this.$alert("请选择要删除的文件");
            }
            // this.del(id);
        },
        // 选择每页显示数量
        async handleSizeChange(val) {
            this.where.pageSize = val;
            this.getSource(this.where);
        },
        // 选择页码
        async handleCurrentChange(val) {
            this.getSource(this.where);
            // this.list = res.data.list;
        },
        getSource() {
            getSource(this.where).then((res) => {
                if (res.code == 200) {
                    let list = res.data.list;
                    list.forEach((item, index) => {
                        let check = this.select_data.find(
                            (info) => info.id === item.id
                        );
                        if (check) {
                            item.check = true;
                        }
                    });
                    this.list = list;
                    // console.log(res.data.pagination.total);
                    this.total = res.data.pagination.total;
                } else {
                    this.$message.error("获取素材失败");
                }
            });
        },
        delSource(ids) {
            let index = this.select_data.findIndex((info) => {
                info.id === ids;
            });
            this.select_data.splice(index, 1);
            this.getSource();
        },
        escAll() {
            this.select_data.forEach((item) => {
                let info = this.list.find((list) => list.id == item.id);
                console.log(info);
                if (info) {
                    item.check = false;
                }
            });
            this.select_data = [];
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-cascader {
    width: 100%;
}
p {
    margin: 0;
}
.type_select {
    height: 100%;
}
.sucai-list {
}
.select-row {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    color: #8590b3;
    .select-item {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 5px;
        cursor: pointer;
        position: relative;
        &.active {
            font-weight: bold;
            &::after {
                content: "";
                height: 2px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: #279aff;
            }
        }
        & + .select-item {
            margin-left: 30px;
        }
    }
}

.ku-box {
    height: 100%;
    display: flex;
    .left {
        flex: 0 0 250px;
        background-color: #fff;
        border-right: 2px solid #f7f9fa;
        .material {
            height: 100%;
            display: flex;
            flex-direction: column;
            .material-title {
                flex: 0 0 54px;
                font-weight: bold;
                display: flex;
                padding: 0 20px;
                align-items: center;
                border-bottom: 2px solid #f7f9fa;
                cursor: pointer;
                p {
                    margin-right: 6px;
                }
            }
            .meun-box {
                flex: 1;
                overflow: auto;
            }
        }
    }
    .right {
        flex: 1;
        height: 100%;
        width: 0;
        // padding-left: 10px;
        .right-box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            .nav {
                flex: 0 0 56px;
                box-sizing: border-box;
                border-bottom: 2px solid #f7f9fa;
                .nav-top {
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 20px;
                }
            }
            .sucai-box {
                flex: 1;
                height: 0;
                display: flex;
                flex-direction: column;
                .sucai-group {
                    flex: 1;
                    height: 0;
                    overflow-y: auto;
                    padding: 5px;
                    display: flex;
                    flex-wrap: wrap;
                    // justify-content: space-around;
                }
                .page-box {
                    padding: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .edit-item {
                        display: flex;
                        align-items: center;
                    }
                    .select-num {
                        margin-right: 10px;
                    }
                    .num {
                        color: #349ffd;
                    }
                }
            }
            .select-box {
                height: 134px;
                background-color: #f7f9fa;
                padding: 20px;
                box-sizing: border-box;
            }
        }
    }
}
.select-group {
    height: 100%;
    overflow-x: auto;
    display: flex;
    flex-wrap: wrap;
    .select-item {
        width: 94px;
        height: 94px;
        background-color: #fff;
        position: relative;
        &:hover {
            .del_btn {
                display: flex;
            }
        }
        .del_btn {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.6);
            display: none;
            align-items: center;
            justify-content: center;
            z-index: 99;
            color: #fff;
            cursor: pointer;
        }
        // background-color: #000;
    }
}
.select-item + .select-item {
    margin-left: 10px;
}
.source_list {
    height: 100%;
    position: relative;
}
.sucai-list {
    width: 190px;
    max-height: 250px;
    // height: 260px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .source-box {
        border: 1px solid #e8ecef;
    }
    .active {
        .source-box {
            border: 1px #279aff solid;
        }
        .chacked {
            background-color: #279aff;
        }
    }
}
.sucai-gruop {
    //   display: flex;
    .item-data {
        display: flex;
        flex-wrap: wrap;
        .up-box {
            display: flex;
        }
        .list {
            flex: 0 0 20%;
            padding: 4px;
            box-sizing: border-box;
        }
        .upload-item {
            width: 100%;
            /deep/ .el-upload-dragger {
                width: 100%;
            }
            /deep/ .el-upload,
            /deep/ .el-upload-dragger {
                width: 100%;
                height: 100%;
            }
        }
    }
}
// .sucai-gruop{

// }
.notup {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // .item-data {
    //     height: 100%;
    //     // align-items: center;
    //     // justify-content: center;
    //     /deep/ .el-upload-dragger {
    //         margin-bottom: 44px;
    //     }
    // }
}
</style>